<template>
  <div id="app" v-if="shopReady" :class="classes">
    <div>
    <!-- <Transition name="mobile" mode="in-out" :enter-active-class="animEnterActiveClass" :leave-active-class="animLeaveActiveClass"> -->
      <!-- <KeepAlive :include="cachedViews"> -->
        <RouterView />
      <!-- </KeepAlive> -->
    <!-- </Transition> -->
    </div>

    <MountingPortal mountTo="#offcanvas">
      <div v-if="!appOnline" class="modal show" tabindex="-1" role="dialog" style="display: block; background-color: rgba(0, 0, 0, 0.5);">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header bg-danger text-white">
              <h5 class="modal-title">Internet non disponibile</h5>
            </div>
            <div class="modal-body text-danger">
              <p>Per poter consultare il catalogo ed ordinare i prodotti devi essere connesso ad internet.</p>
              <p>Controlla di aver attivato la connessione dati o il collegamento Wi-Fi.</p>
            </div>
          </div>
        </div>
      </div>
    </MountingPortal>

    <!--

    <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <strong class="mr-auto">Bootstrap</strong>
        <small>11 mins ago</small>
        <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="toast-body">
        Hello, world! This is a toast message.
      </div>
    </div>

    <div v-if="hasBackendError">
      bla blabla
    </div>
  -->
    
  </div>
</template>

<script>
//import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
//import { faShoppingCart, faBars } from '@fortawesome/free-solid-svg-icons'
import { MountingPortal } from 'portal-vue'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      shopReady: false,
      cachedViews: ['Main'],
      animEnterActiveClass: '',
      animLeaveActiveClass: ''
    }
  },

  computed: {
    classes() {
      return {
        'position-fixed': !this.appOnline
      }
    },
    ...mapGetters(['shopName', 'hasBackendError', 'appOnline'])
  },

  watch: {
    '$route': function(to, from) {
      const toDepth = to.meta.mobileDepth || 0
      const fromDepth = from.meta.mobileDepth || 0
      const toDepthClass = `mobile-depth-${toDepth}`
      const fromDepthClass = `mobile-depth-${fromDepth}`

      if (fromDepth < toDepth) {
        this.animEnterActiveClass = `animated faster slideInUp ${toDepthClass}`
        this.animLeaveActiveClass = ''
      
      } else if (fromDepth > toDepth) {
        this.animEnterActiveClass = ''
        this.animLeaveActiveClass = `animated faster slideOutDown ${fromDepthClass}`
      
      } else {
        this.animEnterActiveClass = ''
        this.animLeaveActiveClass = ''        
      }
    }
  },

  beforeCreate() {
    this.$store.dispatch('loadShop').then(() => {
      this.shopReady = true
    })
  },

  components: {
    MountingPortal
  }
}
</script>

<style scoped>
.mobile-enter {
  z-index: 1000;
}

.mobile-depth-1 {
  z-index: 1040;
}

.mobile-depth-2 {
  z-index: 1080;
}

.animated.transition-enter,
.animated.transition-leave {
  height: 100vh;
}
</style>