import api from '@/services/api'

export default {
  state: {
    user: null
  },
  getters: {
    user: state => state.user
  },
  mutations: {
    SET_USER: (state, payload) => {
      state.user = payload
    }
  },
  actions: {
    fetchUser({ commit }) {
      return api.get('user').then(res => {
        commit('SET_USER', res.data)
      })
    },
    logoutUser({ commit }) {
      return api.post('user/logout').then(() => {
        commit('SET_USER', null)
      })
    }
  }
}