<style scoped>
  .list-group-item.list-group-item-action {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2rem;
  }
</style>

<template>
  <div class="list-group list-group-flush">
    <a v-for="item in items" :key="item.id"
      :href="item.href"
      class="list-group-item list-group-item-action py-3"
      @click.prevent="$emit('item-click', item)">{{ item.name }}</a>
  </div>   
</template>

<script>
export default {
  name: 'CategoryMenu',
  props: {
    items: Array
  }
}
</script>