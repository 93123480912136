<template>
  <div class="s24-mobile-card">
    <nav class="navbar navbar-expand-md navbar-dark fixed-top s24-shop-bg-color s24-navbar-shop">
      <div class="navbar-brand mr-auto ml-2" style="line-height: 40px;">
        <img src="/client/img/icons/android-chrome-192x192.png" width="40" height="40" :alt="shopName" class="d-inline-block align-top">
        <strong class="ml-2">Concludi ordine</strong>
      </div>
      
      <a @click.prevent="$router.replace({name: 'home'})" class="btn mr-1" role="button" style="line-height: 44px;">
        <FontAwesomeIcon :icon="closeIcon" size="lg" fixed-width />
      </a>
    </nav>

    <div style="margin-top: 58px; padding-bottom: 60px;">
      <div v-if="loading" class="d-flex justify-content-center">
        <div class="spinner-border mt-4 text-primary" role="status" style="width: 3rem; height: 3rem;">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div v-else>
        <div v-if="!user" class="container-lg">
          <p class="text-center p-3">Per poter procedere con l'ordine devi registrarti</p>

          <div class="row justify-content-md-center">
            <div class="col-md-6">
              <a class="btn btn-block btn-lg btn-social btn-google" href="/client/auth/google" target="_blank">
                <FontAwesomeIcon :icon="googleIcon" size="lg" /> Accedi con Google
              </a>
              <!--
              <a class="btn btn-block btn-lg btn-social btn-facebook" href="/client/auth/facebook">
                <FontAwesomeIcon :icon="facebookIcon" size="lg" /> Accedi con Facebook
              </a>
              -->
            </div>
          </div>
        </div>

        <div v-else>
          <div style="background-color: #f6f6f6; border-bottom: 1px solid #ddd;">
            <div class="container-lg">
              <div class="d-flex align-items-center py-3">
                <div class="mr-auto">
                  <strong>{{ user.first_name }} {{ user.last_name }}</strong><br>{{ user.email }}
                </div>
                <button class="btn btn-block btn-primary w-25" @click="logoutUser">Esci</button>
              </div>
            </div>
          </div>

          <div class="container-lg">
            <div class="row justify-content-md-center">
              <div class="col-sm col-lg-5">
                <div class="mt-3">
                  <p class="h5">Riepilogo ordine</p>

                  <div class="row" v-for="item in cartItems" :key="item.id">
                    <div class="col-9 pt-1"><small><strong>{{ item.quantity }}</strong> {{ item.product.saleIsBulk ? item.product.cart_um : 'x' }} {{ item.product.name }}</small></div>
                    <div class="col-3 pt-1 text-right"><strong>{{ item.rowPrice | price }}</strong></div>
                  </div>

                  <p class="mt-4 text-right h5">Totale spesa: {{ cartTotal | price }}</p>

                  <small>La consenga a domicilio è gratuita se l'ordine supera &euro; 50, altrimenti il costo è di &euro; 3.</small>
                </div>
              </div>
              <div class="col-sm col-lg-5">
                <div style="background-color: rgb(246, 246, 246);" class="p-3 mt-3 rounded">
                  <CheckoutForm 
                    :user="user"
                    :lastOrder="lastOrder"
                    :lastAddress="lastAddress"
                    :sending="sending"
                    @submit="onFormSubmit"
                  >
                  </CheckoutForm>                  
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons'
import CheckoutForm from '@/components/checkout/CheckoutForm'
import '@/style/bootstrap-social.css'

export default {
  name: 'MobileCheckout',

  data() {
    return {
      loading: true,
      sending: false,
      googleIcon: faGoogle,
      facebookIcon: faFacebook,
      closeIcon: faTimes
    }
  },

  computed: {
    ...mapGetters(['shopName', 'cartTotal', 'cartItems', 'user', 'lastOrder', 'lastAddress'])
  },

  methods: {
    onFormSubmit(payload) {
      this.$store.commit('SET_ORDER', null)
      this.sending = true

      return this.$store.dispatch('createOrder', payload)
        .then(() => {
          this.$router.replace({name: 'order'})
        
        }).finally(() => {
          this.sending = false
        })
    },
    ...mapActions(['fetchCart', 'fetchUser', 'fetchLastOrder', 'fetchLastAddress', 'logoutUser'])
  },
  
  mounted() {
    Promise.all([
      this.fetchLastOrder(),
      this.fetchLastAddress(),
      this.fetchCart(),
      this.fetchUser()
    ]).then(() => this.loading = false)
  },

  components: {
    FontAwesomeIcon,
    CheckoutForm
  }
}
</script>