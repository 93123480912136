<template>
  <div>
    <div v-if="mappedProducts.length == 0" class="pt-3 text-center">
      Nessun prodotto presente
    </div>
    
    <div v-else class="container-fluid">
      <div class="row row-cols-xl-4 row-cols-lg-2 row-cols-md-2 row-cols-1">
        <div v-for="product in mappedProducts" :key="product.id" class="col pt-2 pb-2" style="border-bottom: 1px solid #ddd;">
          <Product :product="product" class="h-100" style="min-height: 150px;"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import Product from '@/components/Product'

export default {
  name: 'ProductList',
  props: {
    products: Array,
  },

  data () {
    return {
      animation: false,
      cartIcon: faShoppingCart
    }
  },

  computed: {
    mappedProducts() {
      return this.products.map((product) => {
        const item = this.cartItems.find((i) => i.product_id == product.id)

        if (item) {
          product.cartQuantity = item.quantity
          product.cartItem = item

        } else {
          product.cartQuantity = 0
        }

        return product
      })
    },
    ...mapGetters([
      'cartItems'
    ])
  },

  methods: {
    addProductToCart(product) {
      this.$store.dispatch('addProductToCart', product)
        .then(() => {
          this.animation = true
        })
    },
    ...mapActions([
      'decrementCartProduct'
    ])
  },

  components: {
    Product
  }
}
</script>
