<style scoped>
/*.cart-view {
  padding-top: 60px;
  background-color: #f00;
  position: fixed;
  height: 100vh;
  width: 400px;
  top: 0;
  right: 0;
}*/

.offpage-view {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 58px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;
}

.offpage-bg {
  position: fixed;
  top: 0;
  left: 0;
  padding-right: 17px;
}

.cart-wrapper {
  width: 100%;
}

.cart-switch {
  color: #fff;
}

.cart-switch.active {
  background-color: #fff;
  color: #000;
}

h2 {
  border-bottom: 1px solid #ddd;
}

.cart-switch.active .cart-label {
  display: none;
}

@media (min-width: 576px) {
  .cart-wrapper {
    width: 400px;
  }

  .cart-switch.active .cart-label {
    display: inline;
  }
}
</style>

<template>
  <div :style="styles">
    <nav class="navbar navbar-expand-md navbar-dark fixed-top s24-shop-bg-color s24-navbar-shop">
      <!--
      <button class="btn d-md-none mr-1" type="button">
        <FontAwesomeIcon :icon="menuIcon" size="lg" fixed-width />
      </button>
      -->

      <RouterLink :to="{name: 'home'}" class="navbar-brand ml-2 mr-auto" style="white-space: normal; font-size: 1.1rem;">
        <img src="/client/img/icons/android-chrome-192x192.png" width="40" height="40" :alt="shopName" class="d-inline-block mr-1">
        <strong>{{ shopName }}</strong>
      </RouterLink>
      
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mr-auto ml-3">
          <li class="nav-item active">
            <RouterLink to="/" class="nav-link">Home</RouterLink>
          </li>
        </ul>
      </div>

      <!-- <RouterLink :to="{name: 'cart', params: {fromMain: true}}" class="btn pl-2 pr-3" role="button" style="line-height: 44px;">
        {{ cartTotal | price }}
        <FontAwesomeLayers full-width class="fa-lg">
          <FontAwesomeIcon :icon="cartIcon" style="margin-top: 4px;"/>
          <FontAwesomeLayersText 
            counter 
            style="background-color: #000; color: #fff;"
            transform="shrink-6 right-20 down-7" 
            position="top-right" 
            :value="cartTotalQuantity" />
        </FontAwesomeLayers>
      </RouterLink> -->
      <button
        ref="cartSwitch"
        class="btn cart-switch" 
        style="line-height: 44px;" 
        :class="{active: cartIsVisible}"
        @click.prevent="toggleCart"
      >
        <span class="pl-2 pr-2 cart-label">
          {{ cartTotal | price }}
          <FontAwesomeLayers full-width class="fa-lg">
            <FontAwesomeIcon :icon="cartIcon" style="margin-top: 4px;"/>
            <FontAwesomeLayersText 
              counter 
              style="background-color: #000; color: #fff;"
              transform="shrink-6 right-20 down-7" 
              position="top-right" 
              :value="cartTotalQuantity" />
          </FontAwesomeLayers>
        </span>
        <span v-show="cartIsVisible" class="d-sm-none px-3">
          <FontAwesomeIcon :icon="closeIcon" size="lg"/>
        </span>
      </button>
    </nav>

    <div class="fixed-top bg-light p-0 d-flex" style="top: 58px; border-bottom: 1px solid #ddd; line-height: 44px">
      <button 
        type="button" 
        class="btn flex-grow-1 flex-md-grow-0 text-left text-uppercase" 
        style="min-width: 250px; border-radius: 0; border-right: 1px solid #ddd; font-weight: 600;"
        @click.prevent="navMenuIsVisible = !navMenuIsVisible">
        <div class="d-flex">
          <div class="flex-grow-1 d-none d-md-block">Reparti</div>
          <div class="flex-grow-1 d-md-none">{{ category ? category.name : 'Vetrina' }}</div>
          <div class="mr-2 d-flex align-items-center justify-content-center"><FontAwesomeIcon :icon="navMenuIcon" fixed-width size="lg"/></div>
        </div>
      </button>

      <!-- <form class="align-self-center form-inline d-none d-sm-block ml-2">
        <input class="form-control mr-sm-2" type="search" placeholder="Cerca" aria-label="Cerca">
      </form> -->

      <RouterLink :to="{name: 'search'}" class="btn px-3" style="line-height: 44px;">
        <FontAwesomeIcon :icon="searchIcon" size="lg" fixed-width />
      </RouterLink>

      <!-- <div class="align-self-center d-none d-md-block ml-auto mr-3">
        <span class="navbar-text ml-auto">{{ cartTotal | price }}</span>

        <button class="btn btn-outline-dark ml-3" @click.prevent="cartIsVisible = !cartIsVisible">
          <FontAwesomeIcon :icon="cartIcon" fixed-width />
        </button>
      </div> -->
    </div>
  
    <div style="margin-top: 117px;">
      <KeepAlive :include="cachedViews">
        <RouterView />
      </KeepAlive>
    </div>

    <div v-if="cartIsVisible" class="d-flex offpage-view">
      <div class="flex-grow-1 text-right d-none d-sm-block">
        <a href="#" @click.prevent="cartIsVisible = false" class="d-block px-3 py-3">
          <FontAwesomeIcon :icon="closeIcon" fixed-width size="2x" style="color: #fff;" />
        </a>
      </div>

      <div style="max-width: 450px; background-color: #fff;" class="d-flex flex-column w-100">
        <div class="flex-grow-1 overflow-auto s24-scrollbar">
          <h2 class="h5 p-3">Carrello</h2>

          <div v-if="cartTotal > 0">
            <CartItemList v-if="cartTotal > 0" style="padding-bottom: 160px;"/>

            <div style="position: absolute; bottom: 0; z-index: 1000; width: 100%; max-width: 450px;">
              <div style="background-image: linear-gradient(to top, rgba(0, 0, 0, 0.25), transparent); height: 20px;"></div>

              <div class="px-3 pb-3 pt-2 bg-light">
                <CartTotal />

                <RouterLink replace :to="{name: 'checkout'}" class="btn btn-success btn-lg btn-block mt-1">Concludi ordine</RouterLink>
              </div>
            </div>
          </div>

          <div v-else style="margin-top: 58px;">
            <div class="text-center p-5">Nessun articolo nel carrello</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="navMenuIsVisible" class="d-flex offpage-view">
      <div style="max-width: 375px; width: 90vw; background-color: #fff;" class="d-flex flex-column">
        <div class="flex-grow-1 overflow-auto s24-scrollbar">
          <CategoryMenu :items="navMenuItems" @item-click="navigateToItem" style="border-bottom: 1px solid #ddd;"/>
        </div>
      </div>
      <div class="flex-grow-1">
        <a href="#" @click.prevent="navMenuIsVisible = false" class="d-block px-3 py-3">
          <FontAwesomeIcon :icon="closeIcon" fixed-width size="2x" style="color: #fff;" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { faShoppingCart, faChevronDown, faStore, faSearch, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import CartItemList from '@/components/CartItemList'
import CategoryMenu from '@/components/CategoryMenu'
import CartTotal from '@/components/CartTotal'

export default {
  name: 'Home',
  data () {
    return {
      cartIcon: faShoppingCart,
      menuIcon: faBars,
      storeIcon: faStore,
      searchIcon: faSearch,
      closeIcon: faTimes,
      navMenuIcon: faChevronDown,
      cartIsVisible: false,
      searchIsVisible: false,
      navMenuIsVisible: false,
      cachedViews: ['Category'],
      category: null
    }
  },

  computed: {
    styles() {
      if (this.navMenuIsVisible || this.cartIsVisible) {
        return {
          position: 'fixed'
        }
      }

      return {}
    },

    navMenuItems() {
      let items = [{
        id: 'home',
        name: 'Vetrina',
        href: this.$router.resolve({name: 'home'}).href
      }]

      this.categories.forEach(item => {
        items.push({
          id: item.id,
          name: item.name,
          href: this.$router.resolve({name: 'category', params: {id: item.id}}).href
        })
      })

      return items
    },

    ...mapGetters([
      'shopName',
      'cartTotal',
      'cartTotalQuantity',
      'categories'
    ])
  },

  methods: {
    toggleCart() {
      this.navMenuIsVisible = false
      this.cartIsVisible = !this.cartIsVisible
      this.$refs.cartSwitch.blur()
    },

    showNavMenu() {
      this.navMenuIsVisible = true
      this.cartIsVisible = false
    },

    selectCategoryById(id) {
      this.category = this.categories.find((item) => item.id == id)
    },

    navigateToItem(item) {
      this.navMenuIsVisible = false

      if (item.id == 'home' && this.$route.name != 'home') {
        this.$router.push({name: 'home'})
        this.category = null

      } else if (!this.category || item.id != this.category.id) {
        this.selectCategoryById(item.id)
        this.$router.push({name: 'category', params: {id: item.id}})
      }
    }
  },

  created() {
    this.$store.dispatch('fetchCategories')
      .then(() => {
        if (this.$route.name === 'category') {
          this.category = this.categories.find(item => item.id == this.$route.params.id)
        }
      })

    this.cartIsVisible = this.$route.meta.withCart
    this.navMenuIsVisible = false

    this.$store.dispatch('fetchCart')
  },

  beforeRouteUpdate(to, from, next) {
    if (to.name === 'category') {
      this.selectCategoryById(to.params.id)
    }

    this.cartIsVisible = to.meta.withCart
    this.navMenuIsVisible = false
    this.$store.dispatch('fetchCart')

    next()
  },

  components: {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText,
    CartItemList,
    CartTotal,
    CategoryMenu
  }
}
</script>
