<template>
  <div>
    <div class="d-flex">
      <div style="min-width: 50px; width: 50px;" class="mr-1">
        <img v-if="imageUrl" :src="imageUrl" class="img-fluid">
      </div>
      <div class="flex-grow-1">
        <div class="product-name">{{ item.product.name }}</div>
        <p class="product-package mb-0">{{ item.product.sales_package }}</p>
        <ProductPrice 
          :product="item.product" 
          style="font-style: normal; font-weight: 600;" 
          class="mb-0"
        />
      </div>
      <div class="ml-2" style="width: 125px; min-width: 125px; font-size: 1.1rem;">
        <div class="d-flex mb-1">
          <div class="text-info" style="font-weight: 600;">{{ quantity }} {{ item.product.cart_um }}</div>
          <div class="text-right ml-auto" style="font-weight: 600;">{{ item.rowPrice | price }}</div>
        </div>
        <div class="btn-group btn-block" role="group">
          <button 
            class="btn btn-danger"
            @click.prevent="updateCartQuantity(item, quantity - item.product.cart_qty_step)">
            <FontAwesomeIcon :icon="decrementIcon" fixed-width />
          </button>    
          <button 
            class="btn btn-success"
            :disabled="quantity >= item.product.cart_qty_max"
            @click.prevent="updateCartQuantity(item, quantity + item.product.cart_qty_step)">
            <FontAwesomeIcon :icon="incrementIcon" fixed-width />
          </button>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <span style="font-size: 0.9rem;">Se il prodotto non è disponibile:</span>
      <div class="btn-group btn-group-justified btn-block mt-1" data-toggle="buttons">
        <button type="button" class="btn btn-light" 
          :class="{active: outOfStockAction == 'replace'}" 
          @click="updateCartItemOutOfStockAction(item, 'replace')"
        > 
          Sostituisci
        </button>
        <button class="btn btn-light" 
          :class="{active: outOfStockAction == 'remove'}" 
          @click="updateCartItemOutOfStockAction(item, 'remove')"
        >
          Togli
        </button>
        <button class="btn btn-light"
          :class="{active: outOfStockAction == 'call'}" 
          @click="updateCartItemOutOfStockAction(item, 'call')"
        >
          Chiamami
        </button>
      </div>              
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { faPlusCircle, faMinusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ProductPrice from '@/components/ProductPrice'

export default {
  name: 'CartItem',
  props: ['item'],

  data () {
    return {
      incrementIcon: faPlusCircle,
      localOutOfStockAction: false,
      localQty: false
    }
  },

  computed: {
    imageUrl() {
      if (this.item.product.images[0]) {
        return '/client/img/product/thumb/' + this.item.product.images[0].id + '/' + this.item.product.images[0].name
      }

      return null
    },

    quantity() {
      if (this.localQty !== false) {
        return this.localQty
      }

      return this.item.quantity
    },

    outOfStockAction() {
      if (this.localOutOfStockAction !== false) {
        return this.localOutOfStockAction
      }

      return this.item.out_of_stock_action
    },
    
    decrementIcon() {
      if (this.quantity <= this.item.product.cart_qty_min) {
        return faTrash
      }

      return faMinusCircle
    }
  },

  methods: {
    updateCartQuantity(item, quantity) {
      if (quantity < item.product.cart_qty_min) {
        quantity = 0
      }

      if (quantity > item.product.cart_qty_max) {
        quantity = item.product.cart_qty_max
      }

      this.localQty = quantity
      
      return this.dispatchUpdateCartItemQuantity(item, quantity)
    },

    dispatchUpdateCartItemQuantity: debounce(function(item, quantity) {
      return this.$store.dispatch('updateCartItemQuantity', { 
        id: item.id,
        quantity: quantity
      }).catch(() => {
        this.localQty = false
      })
    }, 500),

    updateCartItemOutOfStockAction: debounce(function(item, action) {
      this.localOutOfStockAction = action

      return this.$store.dispatch('updateCartItem', { 
        id: item.id,
        out_of_stock_action: action
      }).catch(() => {
        this.localOutOfStockAction = false
      })
    }, 250)
  },

  components: {
    FontAwesomeIcon,
    ProductPrice
  }
}
</script>
