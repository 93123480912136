import axios from 'axios'
import qs from 'qs'

const api = axios.create({
  baseURL: '/api/v1/',
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  paramsSerializer(params) {
    return qs.stringify(params, {arrayFormat: 'comma'})
  }
})

const csrfToken = document.head.querySelector('meta[name="csrf-token"]');
if (csrfToken) {
  api.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken.content;
}

export default api