import api from '@/services/api'

export const SHOP_SET = 'SHOP_SET'

export default {
  state: {
    shop: {}
  },
  getters: {
    shop: state => state.shop,
    shopName: state => state.shop.name,
    shopContactPhone: state => state.shop.mobile
  },
  mutations: {
    [SHOP_SET] (state, payload) {
      state.shop = payload
    }
  },
  actions: {
    loadShop({ commit }) {
      api.get('store').then((res) => {
        commit(SHOP_SET, res.data)
      })
    },
  }
}