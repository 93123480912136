import api from '@/services/api'

export const SET_ORDER = 'SET_ORDER'
export const SET_LAST_ORDER = 'SET_LAST_ORDER'
export const SET_LAST_ADDRESS = 'SET_LAST_ADDRESS'

export default {
  state: {
    order: null,
    lastOrder: null,
    lastAddress: null
  },
  getters: {
    order: (state) => state.order,
    lastOrder: (state) => state.lastOrder,
    lastAddress: (state) => state.lastAddress,
  },
  mutations: {
    [SET_ORDER] (state, payload) {
      state.order = payload
    },
    [SET_LAST_ORDER] (state, payload) {
      state.lastOrder = payload
    },
    [SET_LAST_ADDRESS] (state, payload) {
      state.lastAddress = payload
    }
  },
  actions: {
    fetchLastOrder({ commit }) {
			return api.get('order/last').then((res) => {
				commit(SET_LAST_ORDER, res.data)
			})
		},
    fetchLastAddress({ commit }) {
			return api.get('order/last-address').then((res) => {
				commit(SET_LAST_ADDRESS, res.data)
			})
		},
    createOrder({ commit }, payload) {
      api.post('order', payload).then((res) => {
        commit(SET_ORDER, res.data)
      })
    }
  }
}