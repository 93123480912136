import numeral from 'numeral'
import 'numeral/locales'

export default {
  install(Vue) {
    numeral.locale('it')

    Vue.filter('price', function (value) {
      return numeral(value).format('$ 0,0.00')
    })
  }
}