<template>
  <div class="s24-mobile-card">
    <nav class="navbar navbar-expand-md navbar-dark fixed-top s24-shop-bg-color s24-navbar-shop">
      <div class="navbar-brand mr-auto ml-2" style="line-height: 40px;">
        <img src="/client/img/icons/android-chrome-192x192.png" width="40" height="40" :alt="shopName" class="d-inline-block align-top">
        <strong class="ml-2">Concludi ordine</strong>
      </div>
      
      <a @click.prevent="$router.replace({name: 'home'})" class="btn d-md-none mr-1" role="button" style="line-height: 44px;">
        <FontAwesomeIcon :icon="closeIcon" size="lg" fixed-width />
      </a>
    </nav>

    <div style="margin-top: 58px; padding-bottom: 60px;">
      <div class="p-3" v-if="order">
        <p>Confermiamo la ricezione dell'ordine n. <strong>{{ order.id }}</strong> di <strong>{{ order.total_price | price }}</strong><br>
        con <template v-if="order.delivery"><strong>consegna a domicilio</strong> all'indirizzo <span style="text-decoration: underline;">{{ order.address }}</span></template>
        <template v-else><strong>ritiro in negozio</strong></template>.</p>
        <p>Grazie per aver effettuato il tuo ordine, verrai ricontattato dal negozio al numero <strong>{{ order.phone }}</strong>.</p>

        <button class="btn btn-lg btn-block btn-success mt-4" style="max-width: 600px;" @click="$router.push({name: 'home'})">Torna alla Home</button>
      </div>
      <div v-else class="d-flex justify-content-center pt-4">
        <div class="spinner-border text-primary" role="status" style="width: 3rem; height: 3rem;">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default {
  data() {
    return {
      loading: true,
      closeIcon: faTimes
    }
  },

  computed: {
    ...mapGetters(['shopName', 'order'])
  },

  components: {
    FontAwesomeIcon
  }
}
</script>