import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import catalog from './modules/catalog'
import cart from './modules/cart'
import shop from './modules/shop'
import order from './modules/order'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    catalog,
    cart,
    shop,
    order
  },
  state: {
    appOnline: true,
    hasBackendError: false
  },
  getters: {
    appOnline: (state) => state.appOnline,
    hasBackendError: (state) => state.hasBackendError
  },
  mutations: {
    setAppOnline(state) {
      state.appOnline = true
    },
    setAppOffline(state) {
      state.appOnline = false
    },
    setBackendError(state) {
      state.hasBackendError = true
    }
  }
})
