import './style/app.scss'
import 'animate.css'
//import { library as faLibrary } from '@fortawesome/fontawesome-svg-core'
//import { faFontAwesome } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import VueAuthenticate from 'vue-authenticate'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import PricePlugin from '@/plugins/Price'
import '@/registerServiceWorker'

window.addEventListener('online', () => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('online')
  }

  store.commit('setAppOnline')
})

window.addEventListener('offline', () => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('offline')
  }

  store.commit('setAppOffline')
})


Vue.use(VueAxios, axios)

Vue.use(VueAuthenticate, {
  baseUrl: `${window.location.protocol}//${window.location.host}`,
  providers: {
    google: {
      clientId: '713422281428-0oupk1hm6ksfgsos04jir6nbvid4152b.apps.googleusercontent.com',
      redirectUri: `${window.location.protocol}//${window.location.host}/client/auth/google`,
      url: '/api/v1/auth/google'
    }
  }
})

Vue.use(PricePlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
