<template>
  <div>
    <div class="list-group list-group-flush">
      <div class="list-group-item s24-cart-product" v-for="item in cartItems" :key="item.id">
        <CartItem :item="item" class="mb-2" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CartItem from '@/components/CartItem'

export default {
  name: 'CartItemList',
  computed: {
    ...mapGetters(['cartItems'])
  },
  components: {
    CartItem
  }
}
</script>