<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-dark fixed-top s24-shop-bg-color s24-navbar-shop">
      <div class="navbar-brand mr-auto ml-2" style="line-height: 40px;">
        <img src="/client/img/icons/android-chrome-192x192.png" width="40" height="40" :alt="shopName" class="d-inline-block align-top">
      </div>

      <form class="form-inline flex-grow-1 ml-2 mr-auto" @submit.prevent="closeKeyboard">
        <input ref="search" @input="search" class="form-control" type="search" placeholder="Cerca..." aria-label="Cerca">
      </form>

      <a @click.prevent="$router.back()" class="btn mr-1" role="button" style="line-height: 44px;">
        <FontAwesomeIcon :icon="backIcon" size="lg" fixed-width />
      </a>
    </nav>

    <div style="margin-top: 58px;" class="pt-3">
      <div v-if="!loading && terms && products.length == 0" class="text-center pt-4">Nessun prodotto trovato</div>

      <div v-if="products.length > 0">
        <ProductList :products="products" />

        <MugenScroll :handler="fetchProducts" :should-handle="!loading && hasMoreProducts" />
      </div>

      <div v-if="products.length > 0 && !hasMoreProducts" class="text-center p-3">
        Nessun altro prodotto trovato
      </div> 

      <div v-if="loading" class="d-flex justify-content-center pt-4">
        <div class="spinner-border text-primary" role="status" style="width: 3rem; height: 3rem;">
          <span class="sr-only">Sto cercando...</span>
        </div>
      </div>
      <div v-else-if="hasMoreProducts">
        <br><br><br><br><br>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { debounce } from 'lodash'
import MugenScroll from 'vue-mugen-scroll'
import api from '@/services/api'
import ProductList from '@/components/ProductList'

export default {
  name: 'MobileSearch',

  data() {
    return {
      loading: false,
      backIcon: faTimes,
      terms: '',
      products: [],
      currentPage: null,
      pageCount: null
    }
  },

  computed: {
    hasMoreProducts() {
      return !this.currentPage || (this.currentPage && this.currentPage < this.pageCount)
    },
    ...mapGetters(['shopName'])
  },

  methods: {
    search: debounce(function(e) {
      this.terms = e.target.value
    }, 750),

    closeKeyboard() {
      this.$refs.search.blur()
    },

    fetchProducts() {
      if (!this.hasMoreProducts) {
        return
      }

      this.loading = true
      
      return api.get('product', {
        params: {
          expand: ['images'],
          search: this.terms,
          sort: '-score,+name',
          page: this.currentPage ? this.currentPage + 1 : undefined 
        }
      }).then((res) => {
        if (!this.currentPage) {
          this.products = res.data
        } else {
          this.products = [...this.products, ...res.data]
        }

        this.currentPage = Number(res.headers['x-pagination-current-page'])
        this.pageCount = Number(res.headers['x-pagination-page-count'])

        this.loading = false
      })
    }
  },

  watch: {
    terms(value) {
      this.products = []

      if (!value) {
        this.loading = false
        this.currentPage = null
        this.pageCount = null

        return
      }

      this.currentPage = null
      this.pageCount = null
      this.fetchProducts()
    }
  },

  mounted() {
    this.$nextTick(() => this.$refs.search.focus())
  },
  
  components: {
    FontAwesomeIcon,
    ProductList,
    MugenScroll
  }
}
</script>