import api from '@/services/api'

export const CATEGORY_SET = 'CATEGORY_SET'
export const CATEGORIES_SET = 'CATEGORIES_SET'
export const PRODUCTS_SET = 'PRODUCTS_SET'

export default {
  state: {
    category: null,
    categories: [],
    products: []
  },
  getters: {
    products: (state) => state.products,
    category: (state) => state.category,
    categories: (state) => state.categories
  },
  
  mutations: {
    [CATEGORY_SET] (state, payload) {
      state.category = payload
    },
    [CATEGORIES_SET] (state, payload) {
      state.categories = payload
    },
    [PRODUCTS_SET] (state, payload) {
      state.products = payload
    }
  },

  actions: {
    loadCategory({ commit }, payload) {
      return api.get('category/' + Number(payload.id)).then((res) => {
        commit(CATEGORY_SET, res.data)
      })
    },
    fetchCategories({ commit }) {
      return api.get('category', { 
        params: {
          empty: 0
        }
      }).then((res) => {
        commit(CATEGORIES_SET, res.data)
      })
    },
    loadFeaturedProducts({ commit }) {
      return api.get('product', {
        params: {
          fields: ['id', 'featured', 'product.name', 'product_id', 'product.sales_package', 'product.sales_price'],
          expand: ['product.images'],
          filter: {
            featured: 1
          }          
        }
      }).then((res) => {
        commit(PRODUCTS_SET, res.data)
      })
    },
    loadCategoryProducts({ commit }, payload) {
      return api.get('product', {
        params: {
          fields: [
            'id', 
            'featured', 
            'finalPrice',
            'sales_price', 
            'promo_sales_price',
            'cart_qty_min',
            'cart_qty_step',
            'product.sales_package',
            'product.cart_um'
          ],
          expand: ['product.images', 'price'],
          filter: {
            categoryId: payload.id
          }
        }
      }).then((res) => {
        commit(PRODUCTS_SET, res.data)
      })
    },
    loadCategoryAndProducts({ dispatch, commit }, payload) {
      return dispatch('loadCategory', payload)
        .then(() => {
          return dispatch('loadCategoryProducts', payload)
        })
        .catch((error) => {
          commit('setBackendError', error)
        })
    }
  }
}