<style scoped>
h1 {
  border-bottom: 1px solid #ddd;
}
</style>

<template>
  <div>
    <h1 class="d-none d-md-block h4 p-3">Vetrina</h1>

    <div v-if="featuredProducts.length > 0">
        <ProductList :products="featuredProducts" />

        <MugenScroll :handler="fetchProducts" :should-handle="!loading && hasMoreProducts" />
    </div>

    <div v-if="featuredProducts.length > 0 && !hasMoreProducts && pageCount > 1" class="text-center p-3">
      Non ci sono altri prodotti in vetrina
    </div> 

    <div v-if="loading" class="d-flex justify-content-center">
      <div class="spinner-border mt-4 text-primary" role="status" style="width: 3rem; height: 3rem;">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else-if="hasMoreProducts">
      <br><br><br><br><br>
    </div>
  </div>
</template>

<script>
import MugenScroll from 'vue-mugen-scroll'
import ProductList from '@/components/ProductList'
import api from '@/services/api'

export default {
  name: 'Home',

  data() {
    return {
      loading: true,
      featuredProducts: [],
      currentPage: null,
      pageCount: null
    }
  },

  computed: {
    hasMoreProducts() {
      return !this.currentPage || (this.currentPage && this.currentPage < this.pageCount)
    }
  },

  methods: {
    fetchData() {
      this.loading = true
      this.currentPage = null
      this.featuredProducts = []
      
      return this.fetchProducts()
    },

    fetchProducts() {
      if (!this.hasMoreProducts) {
        return
      }

      this.loading = true
      
      return api.get('product', {
        params: {
          expand: ['images'],
          filter: {
            featured: 1
          },
          sort: 'categoryName,name',
          page: this.currentPage ? this.currentPage + 1 : undefined 
        }
      }).then((res) => {
        if (!this.currentPage) {
          this.featuredProducts = res.data
        } else {
          this.featuredProducts = [...this.featuredProducts, ...res.data]
        }

        this.currentPage = Number(res.headers['x-pagination-current-page'])
        this.pageCount = Number(res.headers['x-pagination-page-count'])

        this.loading = false
      })
    },
  },

  watch: {
    '$route': function() {
      this.fetchData()
    }
  },
  
  created() {
    this.fetchData()
  },

  components: {
    ProductList,
    MugenScroll
  }
}
</script>