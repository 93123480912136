import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main.vue'
import Home from '@/views/Home.vue'
import Category from '@/views/Category.vue'
//import Checkout from '@/views/Checkout.vue'
//import MobileCart from '@/views/MobileCart.vue'
import MobileCheckout from '@/views/MobileCheckout.vue'
import MobileSearch from '@/views/MobileSearch.vue'
import Order from '@/views/Order.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '',
        name: 'home',
        component: Home
      },
      {
        path: '/category/:id',
        name: 'category',
        component: Category
      }
    ]
  },
  {
    path: '/search',
    name: 'search',
    component: MobileSearch
  },
  /*
  {
    path: '/cart',
    name: 'cart',
    component: Main,
    meta: { 
      mobileDepth: 1,
      withCart: true
    }
  },*/
  /*{
    path: '/mobile/checkout',
    name: 'mobile-checkout',
    component: MobileCheckout,
    meta: { 
      mobileDepth: 2
    }
  },*/
  {
    path: '/checkout',
    name: 'checkout',
    component: MobileCheckout,
    meta: { 
      mobileDepth: 2
    }
  },
  {
    path: '/order',
    name: 'order',
    component: Order,
    meta: { 
      mobileDepth: 2
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/client/',
  routes,
  /*scrollBehavior(to, from, savedPosition) {
    if (!savedPosition ||to.name == 'cart') {
      return { x: 0, y: 0 }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }*/
})

export default router
