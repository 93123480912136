<style scoped>
h1 {
  border-bottom: 1px solid #ddd;
}
</style>

<template>
  <div>
    <div>
      <div v-if="products.length > 0">
        <h1 class="d-none d-md-block h4 p-3">{{ category.name }}</h1>

        <ProductList :products="products" />

        <MugenScroll :handler="fetchProducts" :should-handle="!loading && hasMoreProducts" />
      </div>

      <div v-if="products.length > 0 && !hasMoreProducts" class="text-center p-3">
        Non ci sono altri prodotti in questa categoria
      </div> 

      <div v-if="loading" class="d-flex justify-content-center pt-4">
        <div class="spinner-border text-primary" role="status" style="width: 3rem; height: 3rem;">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-else-if="hasMoreProducts">
        <br><br><br><br><br>
      </div>
    </div>
  </div>
</template>

<script>
import MugenScroll from 'vue-mugen-scroll'
import ProductList from '@/components/ProductList'
import api from '@/services/api'

export default {
	name: 'Category',

  data() {
    return {
      loading: true,
      category: null,
      products: [],
      currentPage: null,
      pageCount: null
    }
  },

  computed: {
    hasMoreProducts() {
      return !this.currentPage || (this.currentPage && this.currentPage < this.pageCount)
    },
    featuredProducts() {
      return this.products.filter((storeProduct) => storeProduct.featured)
    }
  },

  methods: {
    fetchData() {
      this.loading = true
      this.currentPage = null
      this.products = []

      const categoryFetch = api.get('category/' + Number(this.$route.params.id))
        .then((res) => {
          this.category = res.data
        })
      
      return Promise.all([
        categoryFetch,
        this.fetchProducts()
      ]).finally(() => {
        this.loading = false
      })
    },

    fetchProducts() {
      if (!this.hasMoreProducts) {
        return
      }

      this.loading = true
      
      return api.get('product', {
        params: {
          expand: ['images'],
          filter: {
            categoryId: this.$route.params.id
          },
          page: this.currentPage ? this.currentPage + 1 : undefined 
        }
      }).then((res) => {
        if (!this.currentPage) {
          this.products = res.data
        } else {
          this.products = [...this.products, ...res.data]
        }

        this.currentPage = Number(res.headers['x-pagination-current-page'])
        this.pageCount = Number(res.headers['x-pagination-page-count'])

        this.loading = false
      })
    },
  },

  watch: {
    '$route': function() {
      this.fetchData()
    }
  },
	
  created() {
    this.fetchData()
	},

	components: {
		ProductList,
    MugenScroll
	}
}
</script>
