import api from '@/services/api'

export const PRODUCT_ADD = 'PRODUCT_ADD'
export const PRODUCT_INCREMENT = 'PRODUCT_INCREMENT'
export const PRODUCT_DECREMENT = 'PRODUCT_DECREMENT'
export const PRODUCT_REMOVE = 'PRODUCT_REMOVE'
export const SET_CART_ITEMS = 'SET_CART_ITEMS'
export const SET_CART_TOTAL = 'SET_CART_TOTAL'
export const SET_CART_TOTAL_QUANTITY = 'SET_CART_TOTAL_QUANTITY'

export default {
	state: {
		cartTotal: 0,
		cartTotalQuantity: 0,
		cartItems: []
	},
	
	getters: {
		cartTotal: (state) => state.cartTotal,
		cartTotalQuantity: (state) => state.cartTotalQuantity,
		cartItems: (state) => state.cartItems
	},

	mutations: {
		[SET_CART_ITEMS] (state, payload) {
			state.cartItems = payload
		},

		[SET_CART_TOTAL] (state, payload) {
			state.cartTotal = payload
		},

		[SET_CART_TOTAL_QUANTITY] (state, payload) {
			state.cartTotalQuantity = payload
		},

		[PRODUCT_ADD] (state, payload) {
			const item = state.cartItems.find((i) => i.product.id == payload.product.id)
			const qtyStep = (payload.product.qtyStep || 1)
			const quantity = Math.abs(payload.quantity || 1) * qtyStep

			if (item) {
				Object.assign(item, {
					product: payload.product,
					quantity: Math.max(item.quantity + quantity, (payload.product.qtyMin || qtyStep))
				})

			} else {
				state.cartItems.push({
					product: payload.product, 
					quantity: Math.max(quantity, (payload.product.qtyMin || qtyStep))
				})
			}
		},
		
		[PRODUCT_INCREMENT] (state, payload) {
			const item = state.cartItems.find((i) => i.product.id == payload.id)
			const quantity = payload.qtyStep || 1

			if (item) {
				Object.assign(item, { quantity: item.quantity + quantity })
			}
		},
		
		[PRODUCT_DECREMENT] (state, payload) {
			const item = state.cartItems.find((i) => i.product.id == payload.id)
			const qtyStep = (payload.qtyStep || 1)
			const qtyMin = (payload.qtyMin || qtyStep)
			
			if (item) {
				const quantity = item.quantity - qtyStep

				if (quantity < qtyMin) {
					state.cartItems.splice(state.cartItems.indexOf(item), 1)
				} else {
					Object.assign(item, { quantity })
				}
			}
		},

		[PRODUCT_REMOVE] (state, payload) {
			const p = state.cartItems.find((item) => item.productId == payload.productId)
			state.cartItems.splice(p, 1)
		}
	},
	actions: {
		fetchCart({ commit }) {
			return api.get('cart', {
				params: {
					expand: [
						'items.product.images'
					]
				}
			}).then((res) => {
				commit(SET_CART_ITEMS, res.data.items)
				commit(SET_CART_TOTAL, res.data.totalPrice)
				commit(SET_CART_TOTAL_QUANTITY, res.data.totalQuantity)
			})
		},

		updateCartItem({ dispatch }, payload) {
			return api.patch('cart-item/' + payload.id, payload).then(() => {
				return dispatch('fetchCart')
			})
		},

		addProductToCart({ dispatch }, payload) {
			return api.post('cart-item', payload).then(() => {
				return dispatch('fetchCart')
			})
		},

		updateCartItemQuantity({ dispatch }, payload) {
			return api.patch('cart-item/' + payload.id, {
				quantity: payload.quantity
			}).then(() => {
				return dispatch('fetchCart')
			})
		},

		incrementCartProduct({ commit }, product) {
			commit(PRODUCT_INCREMENT, product)
		},

		decrementCartProduct({ commit }, product) {
			commit(PRODUCT_DECREMENT, product)
		},
		
		removeProductFromCart({ commit }, product) {
			commit(PRODUCT_REMOVE, product)
		}
	}
}