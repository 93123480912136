<template>
  <div>
    <div class="d-flex">
      <div class="h5">Totale</div>
      <div class="h5 ml-auto text-right">
        {{ cartTotal | price }}<br>
        <small>{{ cartTotalQuantity }} articoli</small>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CartTotal',
  computed: {
    ...mapGetters(['cartTotal', 'cartTotalQuantity'])
  }
}
</script>