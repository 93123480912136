<style scoped>
.quantity {
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;
  /*background-color: rgba(255, 255, 0, 0.3);
  border-radius: 4px;*/
}

p.product-name {
  line-height: 22px;
  margin-bottom: 0;
}

p.price::v-deep {
  font-weight: 600;
  font-size: 18px;
}

p.unit-price {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0;
}

.gallery::v-deep .pswp__caption__center {
  text-align: center;
  font-weight: bold;
}
</style>

<template>
  <div class="row no-gutters">
    <div class="col-5 align-items-center text-center pr-1">
      <a v-if="imageUrl" href="#" @click.prevent="imageOpen = true"><img :src="imageUrl" class="img-fluid"></a>
      <PhotoSwipe
        class="gallery"
        :isOpen="imageOpen"
        :items="images"
        :options="imageOptions"
        @close="imageOpen = false"
      />
    </div>

    <div class="col-7 d-flex flex-column s24-product">
      <div class="flex-grow-1 mb-4">
        <p class="product-name text-break">{{ product.name }}</p>
        <p class="product-package mb-0">{{ product.sales_package }}</p>
        <div class="d-flex align-items-center mt-1">
          <ProductPrice :product="product" class="price mb-0" />
          <p v-if="product.tagHasBulkPrice" class="unit-price text-muted ml-3 mb-0">
            <span class="price">{{ product.bulkFinalPrice | price }}</span>/<span class="um">{{ product.bulkPriceUnit }}</span>
          </p>
        </div>
        <div v-if="product.promo_sales_price" class="d-flex align-items-center text-danger pl-1" style="font-size: 14px;">
          <p class="mb-0 mr-1">
            <del>{{ product.tagRegularPrice | price }}</del>
          </p>
          <p class="mb-0">{{ product.promoName }}</p>
        </div>
      </div>

      <div v-if="cartQuantity > 0" class="d-flex">
        <div class="quantity flex-grow-1 text-center text-info">
          {{ cartQuantity }} {{ product.cart_um }}
        </div>
        <div class="btn-group" role="group">
          <button 
            class="btn btn-danger" style="width: 60px;" 
            @click.prevent="updateCartQuantity(product, cartQuantity - product.cart_qty_step)">
            <FontAwesomeIcon :icon="minusIcon" fixed-width />
          </button>    
          <button 
            class="btn btn-success" style="width: 60px;" 
            :disabled="cartQuantity >= product.cart_qty_max"
            @click.prevent="updateCartQuantity(product, cartQuantity + product.cart_qty_step)">
            <FontAwesomeIcon :icon="plusIcon" fixed-width />
          </button>
        </div>
      </div>

      <div v-else class="text-right">
        <button class="btn btn-success"  style="width: 60px;"  @click.prevent="addToCart(product)">
          <FontAwesomeIcon :icon="plusIcon" fixed-width />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
import { faShoppingCart, faPlusCircle, faMinusCircle, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { PhotoSwipe } from 'v-photoswipe'
import ProductPrice from '@/components/ProductPrice'

export default {
  name: 'Product',
  props: ['product'],

  data () {
    return {
      imageOpen: false,
      imageOptions: {
        index: 0,
        shareEl: false,
        fullscreenEl: false,
        tapToToggleControls: false,
        maxSpreadZoom: 2.5
      },
      cartIcon: faShoppingCart,
      plusIcon: faPlusCircle,
      minusIcon: faMinusCircle,
      loadingIcon: faCircleNotch,
      animation: false,
      adding: false,
      localCartQuantity: false
    }
  },

  computed: {
    images() {
      return this.product.images.map((image) => {
        return {
          src: '/client/img/product/gallery/' + image.id + '/' + image.name + '?' + 202012021823,
          w: 600,
          h: 600,
          title: this.product.name
        }
      })
    },

    imageUrl() {
      if (this.product.images[0]) {
        return '/client/img/product/thumb/' + this.product.images[0].id + '/' + this.product.images[0].name
      }

      return null
    },

    cartQuantity() {
      if (this.localCartQuantity !== false) {
        return this.localCartQuantity
      }

      const item = this.cartItems.find(item => item.product_id == this.product.id)
      return item ? item.quantity : 0
    },

    ...mapGetters(['cartItems'])
  },

  methods: {
    addToCart(product) {
      this.localCartQuantity = product.cart_qty_min
      this.adding = true

      return this.dispatchAddProductToCart(product)
    },

    updateCartQuantity(product, quantity) {
      if (quantity < product.cart_qty_min) {
        quantity = 0
      }

      if (quantity > product.cart_qty_max) {
        quantity = product.cart_qty_max
      }

      this.localCartQuantity = quantity
      
      return this.dispatchUpdateCartItemQuantity(product, quantity)
    },

    dispatchAddProductToCart(product) {
      return this.$store.dispatch('addProductToCart', { 
        product_id: product.id,
        quantity: product.cart_qty_min
      
      }).catch(() => {
        this.localCartQuantity = false
      
      }).finally(() => this.adding = false)
    },

    dispatchUpdateCartItemQuantity: debounce(function(product, quantity) {
      let dispatch = () => {
        if (this.adding) {
          setTimeout(dispatch, 250)

        } else {
          return this.$store.dispatch('updateCartItemQuantity', { 
            id: product.cartItem.id,
            quantity: quantity
          }).catch(() => {
            this.localCartQuantity = false
          })
        }
      }

      return dispatch()

    }, 250)
  },

  watch: {
    cartItems: function() {
      this.localCartQuantity = false
    }
  },

  components: {
    FontAwesomeIcon,
    PhotoSwipe,
    ProductPrice
  }
}
</script>
