<style scoped>
.form-group label {
  font-weight: 600;
}
</style>

<template>
  <form @submit.prevent="submitForm()" class="needs-validation" :class="classes">
    <div class="">
      <div class="form-group">
        <label for="mobile-phone">Consegna</label>
        <div class="custom-control custom-radio">
          <input type="radio" id="delivery-1" class="custom-control-input" v-model="delivery" :value="false">
          <label class="custom-control-label font-weight-normal" style="font-size: 1.1em;" for="delivery-1">
            Ritiro in negozio
          </label>
        </div>
        
        <div class="custom-control custom-radio mt-2">
          <input type="radio" id="delivery-2" class="custom-control-input" v-model="delivery" :value="true">
          <label class="custom-control-label font-weight-normal" style="font-size: 1.1em;" for="delivery-2">
            Consegna a domicilio
          </label>
        </div>
      </div>

      <div class="form-group" v-if="delivery == true">
        <label for="mobile-phone">Indirizzo</label>
        <input type="text" class="form-control" id="mobile-phone" aria-describedby="mobile-phone-help" v-model="address" required>
        <div v-for="(error, i) in addressErrors" :key="i" class="invalid-feedback">{{ error }}</div>
      </div>
      
      <div class="form-group">
        <label for="mobile-phone">Numero di cellulare</label>
        <input type="tel" class="form-control" id="mobile-phone" aria-describedby="mobile-phone-help" v-model="mobilePhone" required>
        <div v-for="(error, i) in mobilePhoneErrors" :key="i" class="invalid-feedback">{{ error }}</div>
      </div>

      <div>
        <p>Invieremo al tuo numero di cellulare la conferma dell’ordine e l’orario della consegna a domicilio, se richiesta.</p>
        <p>Una copia completa dell'ordine verrà inviata al tuo indirizzo email.<br>
          Per eventuali comunicazioni con il negozio o richieste di informazioni sui prodotti, anche prima dell'acquisto, contattare il numero di telefono <a :href="`tel:${shopContactPhone}`">{{ shopContactPhone }}</a>.</p>
        <p>Si prega di leggere sempre l'etichetta, le avvertenze e le istruzioni fornite sul prodotto prima di utilizzarlo o consumarlo.</p>
      </div>
    </div>

    <div class="p-3" style="border-bottom: 1px solid #ddd; padding-top: 0 !important;">
      <slot></slot>
    </div>

    <div class="pt-3">
      <div class="custom-control custom-checkbox mt-1">
        <input type="checkbox" class="custom-control-input" id="terms-and-conditions" v-model="shopTermsConfirmation" required>
        <label class="custom-control-label" for="terms-and-conditions"><small>Accetto le <a href="/static/condizioni_vendita.pdf" target="_blank">condizioni di vendita del negozio</a></small></label>
        <div v-for="(error, i) in shopTermsConfirmationErrors" :key="i" class="invalid-feedback">{{ error }}</div>
      </div>

      <div class="custom-control custom-checkbox mt-1">
        <input type="checkbox" class="custom-control-input" id="s24-terms-and-conditions" required>
        <label class="custom-control-label" for="s24-terms-and-conditions"><small>Accetto le <a href="/static/condizioni_servizio.pdf" target="_blank">condizioni del servizio SpesaWeb24</a></small></label>
      </div>

      <div class="custom-control custom-checkbox mt-1">
        <input type="checkbox" class="custom-control-input" id="privacy-policy" required>
        <label class="custom-control-label" for="privacy-policy"><small>Accetto il trattamento dei miei dati personali secondo quanto previsto dalla <a href="/static/privacy_policy.pdf" target="_blank">Privacy Policy</a></small></label>
        <div v-for="(error, i) in shopTermsConfirmationErrors" :key="i" class="invalid-feedback">{{ error }}</div>
      </div>

      <div v-if="sending" class="d-flex justify-content-center">
        <div class="spinner-border mt-4 text-primary" role="status" style="width: 3rem; height: 3rem;">
          <span class="sr-only">Sending...</span>
        </div>
      </div>
      <button v-else type="submit" class="btn btn-lg btn-block btn-primary mt-4">Invia ordine</button>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CheckoutForm',
  
  props: {
    user: Object,
    lastOrder: Object,
    lastAddress: String,
    cartItems: Array,
    sending: Boolean
  },
  
  data() {
    return {
      validated: false,
      delivery: this.lastOrder ? this.lastOrder.delivery : false,
      deliveryErrors: [],
      address: this.lastAddress,
      addressErrors: [],
      mobilePhone: this.lastOrder.phone,
      mobilePhoneErrors: [],
      shopTermsConfirmation: false,
      shopTermsConfirmationErrors: []
    }
  },

  computed: {
    classes() {
      return {
        'was-validated': this.validated
      }
    },
    ...mapGetters(['shopContactPhone'])
  },
  
  methods: {
    submitForm() {
      this.validated = false
      let hasErrors = false

      let addressErrors = []
      if (this.delivery && !this.address) {
        addressErrors.push('Devi specificare un indirizzo per la consegna')
        hasErrors = true
      }
      this.addressErrors = addressErrors

      let mobilePhoneErrors = []
      if (!this.mobilePhone) {
        mobilePhoneErrors.push('Inserisci il numero di cellulare')
        hasErrors = true
      }
      this.mobilePhoneErrors = mobilePhoneErrors

      let shopTermsConfirmationErrors = []
      if (!this.shopTermsConfirmation) {
        shopTermsConfirmationErrors.push('Devi confermare i termini')
        hasErrors = true
      }
      this.shopTermsConfirmationErrors = shopTermsConfirmationErrors

      this.validated = true

      if (!hasErrors) {
        this.$emit('submit', {
          delivery: this.delivery,
          address: this.address,
          phone: this.mobilePhone
        })        
      }
    }
  }
}
</script>